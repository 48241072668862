import * as  React  from 'react';
import { ComponentProps, FC } from 'react';
const combineComponents = (...components: FC<React.PropsWithChildren<any>>[]): FC<React.PropsWithChildren<unknown>> => {
    return components
        .reverse()
        .reduce(
            (AccumulatedComponents, CurrentComponent) => {
                return ({ children }: ComponentProps<FC<React.PropsWithChildren<unknown>>>): JSX.Element => {
                    return (
                        <AccumulatedComponents>
                            <CurrentComponent>{children}</CurrentComponent>
                        </AccumulatedComponents>
                    );
                };
            }, ({ children }) => <>{children}</>
        );
};
export default combineComponents;
