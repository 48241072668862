import * as React from 'react';
import {Suspense, lazy } from 'react';

import {  Route, Routes, useNavigate} from 'react-router-dom';
import { Layout } from './components/Layout';
import ClipLoader from 'react-spinners/ClipLoader';



import Login from './Auth/login';
import Logout from './Auth/Logout';
import Profile from './components/Pages/Management/Profile';

import PrivateWrapper from './Auth/PrivateRoute';


import AuthProvider  from './Auth/AuthProvider'
import ReferenceDataProvider from './Context/ReferenceDataContext';
import ConfirmDialogProvider from './Context/ConfirmDialogContext';

import Test from './components/Pages/Test/Test';
import { ErrorBoundary } from 'react-error-boundary';
import { Button, Row, Col, Container } from 'reactstrap';

const Home = lazy(()=>import('./components/Pages/Home'));
const Competitors = lazy(()=>import('./components/Pages/Competitors/Competitors'));
const Competitions = lazy(()=>import('./components/Pages/Competitions/Competitions'));
const Flights = lazy(()=>import('./components/Pages/Flights/Flights'));
const Tasks = lazy(()=>import('./components/Pages/TaskDefinition/Tasks'));
const Admin = lazy(()=>import('./components/Pages/Admin/Admin'));
const Scoring = lazy(()=>import('./components/Pages/Scoring/Scoring'));
const Results = lazy(()=>import('./components/Pages/Results/Results'));

interface IErrorFallback {
    error: Error;
    resetErrorBoundary: ()=>void
}
 function ErrorFallback(details:IErrorFallback) {
    return (
        <Container>
          <Row>
              <Col>
                <span className="h3">Oops! Something went wrong:</span>
                </Col>
                </Row>
            <Row>
                <Col>
                <span>The error message was: </span>    
                <pre>{details.error.message}</pre>
                <Button color="danger" 
                    onClick={details.resetErrorBoundary}>
                        Try again
                </Button>
              </Col>
          </Row>
      </Container>

    )
  }
export default function App() {
     
    const navigate = useNavigate();

      return (
        <ErrorBoundary
        FallbackComponent={ErrorFallback}
        onReset={() => {
          navigate("/");
        }}
      >
            <AuthProvider>
              <ReferenceDataProvider>
                  <ConfirmDialogProvider>
                      <Layout>

                          <Suspense fallback={<ClipLoader color={"aqua"} loading />}>
                        <Routes>
                            <Route path='/' element={<Home />} />
                            <Route path='/login' element={<Login  />} />
                            <Route path='/logout' element={<Logout />} />
                            <Route path='/Results' element={<Results/>} />                            
                            <Route path='/Results/:CompetitionID' element={<Results />} />
                            <Route path="/Competitors" element={<Competitors/>} />
                            <Route path="/Flights" element={<Flights/>} />

                              <Route path="/Competitions" element={<PrivateWrapper />}>
                                  <Route path="/Competitions" element={<Competitions/>} />
                              </Route>
                              <Route path="/Tasks" element={<PrivateWrapper />}>
                                  <Route path="/Tasks" element={<Tasks/>} />
                              </Route>
                              <Route path="/Scoring" element={<PrivateWrapper />}>
                                  <Route path="/Scoring" element={<Scoring/>} />
                              </Route>
                              <Route path="/Admin" element={<PrivateWrapper />}>
                                  <Route path="/Admin" element={<Admin/>} />
                              </Route>
                              <Route path="/Profile" element={<PrivateWrapper />}>
                                  <Route path="/Profile" element={<Profile/>} />
                              </Route>
                              <Route path="/Test" element={<PrivateWrapper />}>
                                  <Route path="/Test" element={<Test/>} />
                              </Route>                          
                            </Routes>
                            </Suspense>
                      </Layout>
                </ConfirmDialogProvider>
            </ReferenceDataProvider>
            </AuthProvider>
        </ErrorBoundary>
    );
  }

