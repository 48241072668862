import { USER_DETAILS } from "../Globals";
import { IUserDetails } from "../models/IPreferences";
import { LogError } from "../services/Logging";
import Auth, { ICredentials, IUser } from "./Auth";
import { useUser } from "./useUser";


export interface IUseAuthProvider {
    signIn: (creds: ICredentials) => Promise<boolean>; 
    signOut: () => Promise<void>;
    isAuthenticated: () => boolean;
    isAdmin: () => boolean;
    saveUser: (user:IUser) => void;
    user: IUser | null;
}   

const useAuthProvider = (): IUseAuthProvider => {
    const [user, setUser] = useUser();

    const signIn = async (creds: ICredentials):Promise<boolean> => {
        setUser(null);
        let signinresult = await Auth.signIn(creds)
            .then(response => {
                if (response.status===200) {
               // fetch prefs
                let tk = response.parsedBody;
                Auth.getUserDetails(tk)
                    .then(details => {
                        setUser({
                            token: tk ?? null,
                            userDetails: details.parsedBody as IUserDetails
                        });
                    })
                    return true;
                }
                else {
                    return false;
                }
            })

            .catch((e:Error) => {
                setUser(null);
                LogError(`Signin error: ${e.message}`, e);
                return false;
            });
        return signinresult;
    }
    const signOut = async () => {
        Auth.signOut()
            .then(() => {
                setUser(null);                
            });
    }

    const isAuthenticated = () => {
        var donerelog=localStorage.getItem(`${USER_DETAILS}_Relog`);
        if (donerelog===null) {
            localStorage.setItem(`${USER_DETAILS}_Relog`,"true")
            signOut();
            return false;
        }

        return user !== null;
    }

    const isAdmin = () => {
        return (user?.userDetails?.admin ?? false)
    }

    const saveUser = (user:IUser)=>  {
        Auth.saveUser(user);
    }

    return {
        user,
        signIn,
        signOut,
        isAuthenticated,
        isAdmin,
        saveUser,
    }
};
export default useAuthProvider;