import combineComponents from "../components/Utility/CombineComponents";
import { CompetitorsProvider } from "./CompetitorsContext";
import { CompetitionsProvider } from "./CompetitionsContext";
import { GlidersProvider } from "./GliderDataContext";

import { TurnpointsProvider } from "./TPDataContext";
import { FlightsProvider } from "./FlightsContext";
import { TasksProvider } from './TasksContext';

import { UsersProvider } from "./UsersContext";
import { WindsProvider } from "./WindsContext";
import { DateProvider } from "./DateContext";
import { CompClassesProvider } from "./ClassesContext";
import { TimeZoneProvider } from "./TimeZoneContext";
import { HeightsProvider } from "./HeightsContext";


const ReferenceDataProvider = combineComponents(

    TimeZoneProvider,
    CompClassesProvider,
    UsersProvider,
    CompetitorsProvider,
    FlightsProvider,
    WindsProvider,
    HeightsProvider,
    TasksProvider,
    CompetitionsProvider,
    TurnpointsProvider,
    GlidersProvider,
    DateProvider,


)
export default ReferenceDataProvider