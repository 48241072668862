import * as React from "react";
import {  ReactNode } from 'react';
import ConfirmModal, { DialogType } from '../components/Utility/ConfirmModal';

const noop = () => { };

interface IConfirmDialogContext {
    openDialog: ((opts: IDialogConfig) => void);
}
export interface IDialog {
    title: string;
    message: string;
    type?: DialogType;
    closeTimeout?: number;
}

interface IDialogConfig extends IDialog{
    yesLabel?: string;
    noLabel?: string;
    actionCallback: (result: boolean) => void;
}

export interface IDialogConfirmation extends IDialog {
    yesLabel?: string;
    noLabel?: string;
}


const emptyDialogConfig: IDialogConfig = { title: '', message: '', actionCallback: noop };

const ConfirmDialogContext = React.createContext<IConfirmDialogContext>({openDialog: noop});

const ConfirmDialogProvider = ({ children }: { children: ReactNode }) => {
    const [dialogOpen, setDialogOpen] = React.useState(false);
    const [dialogConfig, setDialogConfig] = React.useState<IDialogConfig>(emptyDialogConfig);

    const openDialog = (opts: IDialogConfig):void => {
        setDialogOpen(true);
        setDialogConfig(opts);
    };

    const resetDialog = () => {
        setDialogOpen(false);
        setDialogConfig(emptyDialogConfig);
    };

    const onConfirm = () => {
        resetDialog();
        dialogConfig.actionCallback(true);
    };

    const onDismiss = () => {
        resetDialog();
        dialogConfig.actionCallback(false);
    };

    return (
        <ConfirmDialogContext.Provider value={{ openDialog }}>
            <ConfirmModal
                title={dialogConfig.title}
                type={dialogConfig.type?? DialogType.warning}
                content={dialogConfig.message}
                show={dialogOpen}
                yesLabel={dialogConfig.yesLabel ?? 'Yes'}
                noLabel={dialogConfig.noLabel ?? 'No'}
                handleClose={onDismiss}
                handleYes={onConfirm}
                closeTimeout={dialogConfig.closeTimeout}               
            />
            {children}
        </ConfirmDialogContext.Provider>
    );
};

const useConfirmDialog = () => {
    const { openDialog } = React.useContext(ConfirmDialogContext);

    const showConfirmation = (options: IDialogConfirmation):Promise<boolean> =>
        new Promise((res) => {
            openDialog({ actionCallback: res, ...options });
        });

    return { showConfirmation };
};

const useWarningDialog = () => {
    const { openDialog } = React.useContext(ConfirmDialogContext);
    const showWarning = (options: IDialog): Promise<boolean> =>
        new Promise((resolve) => {
            openDialog({ actionCallback: resolve, type: DialogType.warning, yesLabel: 'OK', noLabel: '', ...options });
        });
    return { showWarning};
}

const useSuccessDialog = () => {
    const { openDialog } = React.useContext(ConfirmDialogContext);
    const showSuccess = (options: IDialog): Promise<boolean> =>
        new Promise((resolve) => {
            openDialog({ actionCallback: resolve, yesLabel: 'OK', noLabel: '', type: DialogType.success, ...options });
        });
    return { showSuccess };
}

const useDialogs = () => {
    return {confirmDialog: useConfirmDialog, warningDialog: useWarningDialog, successDialog: useSuccessDialog}
}

export {useDialogs, useConfirmDialog, useWarningDialog, useSuccessDialog }

export default ConfirmDialogProvider;
