import * as React from "react";


import { useCompetitions } from '../../Context/CompetitionsContext';
import CompetitionSelector from "./CompetitionSelector";
import { ICompetition } from "../../models/Competition";

interface IProps {
    presetid?: number | (() => number);
    handleSelect? (s: any[]): void;
    inputProps?: any;
    size?: 'large' | 'lg' | 'small' | 'sm';
}

export default function CompetitionSetter(props: IProps) {

    const { selectedCompetition, setCompetition } = useCompetitions();

    return (
        <CompetitionSelector 
            {...props}
            presetid={selectedCompetition?.ID}
            handleSelect={(val=>{
                setCompetition(val[0] as ICompetition);
                if (props.handleSelect) {
                    props.handleSelect(val);
                }
            })}

        />
    );

}
