// Competition Class
import { produce } from 'immer';
import {  useState,  } from 'react';
import { IToken } from '../Auth/Auth';
import { API_ENDPOINTS, API_URL } from '../Globals';
import { deleteItem, post, } from '../services/FetchWrapper';
// eslint-disable-next-line
import { Log, LogError } from '../services/Logging';

export interface ICompClass extends Record<string, any> {
    ID: number;
    CompetitionID: number;
    Title: string ;
    Seq: number;
}

export enum CompClassActionType {
    INSERT = 'INSERT',
    DELETE = 'DELETE',
    LOAD = 'LOAD',
    UPDATE_TITLE = 'UPDATE_TITLE',
    UPDATE_ALL = 'UPDATE_ALL',
    UPDATE_ROW = 'UPDATE_ROW',
}

export type CompClassAction = {
    type: CompClassActionType;
    payload?: any;
}

export const useCompClassReducer = (initState: ICompClass[]) => {
    const [state, setState] = useState(initState);

    const dispatch = async (action: CompClassAction) => {
        let newState = await compclassReducer(state, action);
        setState(newState);
    }
    
    return [state, dispatch] as const;   // need the 'as const' to get Typescript inferencing working
}

const postCompClass = async (compclass: ICompClass, token:IToken) => {
    return post<ICompClass>(`${API_URL}/${API_ENDPOINTS.SAVECOMPCLASS}`, compclass, token)
    .catch(async error => {
        LogError(`Class Update error ${error}`)
    })
}


const deleteCompClass = async (classid: number|undefined , token:IToken) => {
    if (classid) {
    return deleteItem<ICompClass>(`${API_URL}/${API_ENDPOINTS.DELETECOMPCLASS}/${classid}`, token)
    .catch(async error => {
        LogError(`Comp Class Delete error ${error}`)
    })
    }
}

export async function compclassReducer (compclasses: ICompClass[], action:CompClassAction):Promise<ICompClass[]> {

    return await produce(compclasses, async (draftCompClasses) => {
        //Log(`compclassReducer: ${action.type} with payload `, action.payload);
        switch (action.type) {

            case CompClassActionType.INSERT: {
                //payload is {value: class, token: auth?.user?.token}
                let cls = action.payload.value as ICompClass;
                await postCompClass(cls, action.payload.token)
                    .then(resp=> {
                        if (resp) {
                            let newclass = resp.parsedBody
                            if (newclass) {
                                draftCompClasses.unshift(newclass);
                            }
                        }
                    })

                break;
            }
            case CompClassActionType.DELETE: {
                //payload is { ID, token},
                let idx = draftCompClasses.findIndex(c => { return c.ID === action.payload.ID })
                if (idx !== -1) {
                    let classid = draftCompClasses[idx].ID;
                    draftCompClasses.splice(idx, 1);
                    await deleteCompClass(classid, action.payload.token)
                }
                break;
            }
            case CompClassActionType.UPDATE_TITLE: {
                //payload is {ID: which to rename, value: string
                let idx = draftCompClasses.findIndex(c => { return c.ID === action.payload.ID })
                if (idx !== -1) {
                    draftCompClasses[idx].Title = action.payload.value;
                }
                break;
            }


            case CompClassActionType.UPDATE_ROW: {

                //payload is {ID: changedID, value:changedvalue, token: auth?.user?.token},
                let idx = draftCompClasses.findIndex(c => { return c.ID?.toString() === action.payload.ID })
                if (idx !== -1) {
                    draftCompClasses[idx] = { ...draftCompClasses[idx], ...action.payload.value }
                    postCompClass(draftCompClasses[idx], action.payload.token)
                }
                break;
            }

            case CompClassActionType.LOAD: {
                draftCompClasses.length = 0;
                let list = action.payload.value as ICompClass[]
                list.forEach((compclass: ICompClass) => {
                    draftCompClasses.push({
                        ID: compclass.ID,
                        CompetitionID: compclass.CompetitionID,
                        Title: compclass.Title,
                        Seq: compclass.Seq,
                    })
                })                
                break;
            }

            default:{
                throw new Error(`Comp Class Reducer: Unrecognised Action Type ${action.type}`)
                }
        }
    })
}
