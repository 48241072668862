import * as React from "react";
import { useState, useEffect, createContext,  ReactNode } from 'react';
import { CompetitionAction, CompetitionActionType, ICompetition, useCompetitionReducer} from "../models/Competition";
import { useAuth } from "../Auth/AuthProvider";
import {   getItem, post, retrieveItems } from "../services/FetchWrapper";
import { API_ENDPOINTS, API_URL } from "../Globals";
import { IPrefs } from "../models/IPreferences";
import { useErrorHandler } from 'react-error-boundary';
import {IScoringConfig} from 'ladderscoring';
// eslint-disable-next-line
import { Log, LogError } from "../services/Logging";
import { useDate } from "./DateContext";

export interface CompetitionsContextData {
    competitions: ICompetition[];
    competitionsDispatch: (action: CompetitionAction) => void;
    findCompetition: (id: string | number | undefined) => ICompetition | undefined;
    sortedCompetitions: () => ICompetition[];
    visibleCompetitions: () => ICompetition[];
    selectedCompetition?: ICompetition;
    setCompetition: (comp:ICompetition|undefined, save?:boolean) => void;
    ScoringConfig? : IScoringConfig;
    saveScoringConfig: (config: IScoringConfig) => void;
    isScorer: ()=>boolean;
    loading:boolean;
}
//const ENDPOINT = API_ENDPOINTS.SCORERCOMPS;
const ENDPOINT = API_ENDPOINTS.COMPETITIONS;

const CompetitionsContext = createContext<CompetitionsContextData>(
    { 
        competitions: [],
        competitionsDispatch: () => undefined,
        findCompetition: () => undefined,
        sortedCompetitions: () => [],
        visibleCompetitions: () => [],
        selectedCompetition: undefined,
        setCompetition: ()=>undefined,
        ScoringConfig:  undefined,
        saveScoringConfig: async ()=>undefined,
        isScorer: ()=>false,
        loading:false,

});

export const CompetitionsProvider = ({ children }: { children: ReactNode }) => {
    const [competitions, competitionsDispatch] = useCompetitionReducer([])
    const [selectedCompetition, setSelectedCompetition] = useState<ICompetition|undefined>(undefined)
    const [scoringconfig, setScoringConfig] = useState<IScoringConfig|undefined>(undefined);

    // eslint-disable-next-line
    const [loading, setLoading] = useState(false);
    const auth = useAuth();        
    const handleError = useErrorHandler()
    useEffect(() => {

        const getCompetitions = async () => {
            let token = auth?.user?.token ?? null;

            //Log(`getCompetitions from ${API_URL}/${ENDPOINT}`)
            setLoading(true);
            retrieveItems<ICompetition>(`${API_URL}/${ENDPOINT}`, '', true,token)
            .then(async (items)=> {
                if (items) {
                    await competitionsDispatch({
                        type: CompetitionActionType.LOAD,
                        payload: { value: items }
                    });
                    if (auth?.user?.userDetails?.userPrefs?.CompetitionID) {
                        //Log(`getCompetitions: set selected to ${auth?.user?.userDetails?.userPrefs.CompetitionID}`)
                        setCompetition(items.find(c=>c.ID===auth?.user?.userDetails?.userPrefs.CompetitionID))  
                    }   
                }
            })
            .catch(e=>{
                LogError(`getCompetitions: ${(e as Error).message}`)
                handleError(new Error(`getCompetitions error ${(e as Error).message}`));
            })
            .finally(()=> {setLoading(false)});          

        }
        getCompetitions()
        .catch(e=>{ handleError(new Error(`getCompetitions error ${(e as Error).message}`))})
    }, 
// supress warning that dispatch function should be in dependencies
// eslint-disable-next-line             
    [ auth?.user?.token,]);


    useEffect(() => {

        const getScoringConfig = async () => {

            let token = auth?.user?.token ?? null;
            if (token && selectedCompetition) {
                //Log(`getScoringConfig from ${API_URL}/${API_ENDPOINTS.SCORINGCONFIG}/${selectedCompetition.ID}`)
                setLoading(true);
                getItem<IScoringConfig>(`${API_URL}/${API_ENDPOINTS.SCORINGCONFIG}/${selectedCompetition.ID}`, '', token)

                .then(config=> {
                    if (config) {                       
                        setScoringConfig(config);
                    }
                })
                .catch(e=>{
                    LogError(`getScoringConfig: ${(e as Error).message}`)
                    handleError(new Error(`getScoringConfig error ${(e as Error).message}`));
                })
                .finally(()=> {setLoading(false)});          
            }
        }
        getScoringConfig()
        .catch(e=>{ handleError(new Error(`getScoringConfig error ${(e as Error).message}`))})
    }, 
// supress warning that dispatch function should be in dependencies
// eslint-disable-next-line             
    [ auth?.user?.token, selectedCompetition]);


    const findCompetition = (compid: string | number | undefined) => {
        let comp = competitions.find(c=> { return c.ID === compid });
        return comp;
    }
    const sortedCompetitions = () => {
        return [...competitions].sort((c1, c2) => { return (c1?.Title ?? '').localeCompare((c2?.Title ?? '')) })
    }
    const visibleCompetitions = () => {
        // list of competions visible to this user
        let comps = competitions.filter(c=>{     
            //Log(`visibleCompetitions for `,c, ` public=${c.Public}, admin=${auth?.isAdmin()}, scorer=${c.Scorer === auth?.user?.userDetails?.userid}`)       
            return (
                c.Public ||                                         // Public always visible
                auth?.isAdmin() ||                                    // admins can see all comps]
                c.Scorer === auth?.user?.userDetails?.userid        // this user is Scorer for this comp
            )        
        } )
        return [...comps].sort((c1, c2) => { return (c1?.Title ?? '').localeCompare((c2?.Title ?? '')) })
    }
    const {setDate} = useDate();
    
    const setCompetition = (comp:ICompetition|undefined, save:boolean=true) => {
        //Log(`Set selected comp `, comp, `with start date ${comp?.StartDate}`)
        setSelectedCompetition(comp);
        // set the Selected date to the start of the comp...
        setDate(comp?.StartDate ?? '')

        // Save this back to the db...
        //console.trace(`SetCompetition ${comp?.ID} for user ${auth?.user?.userDetails?.userid}`)
        if (save) {
            let prefs:IPrefs = {ID:auth?.user?.userDetails?.userid ?? 0, CompetitionID: comp?.ID ?? 0}
            if (auth?.user?.userDetails) {
                auth.user.userDetails.userPrefs = prefs;
                auth?.saveUser(auth?.user)
            }
        }
    }

    const saveScoringConfig= async (config:IScoringConfig)=> {
        setScoringConfig(config);
        return post<IScoringConfig>(`${API_URL}/${API_ENDPOINTS.SCORINGCONFIG}`, config, auth?.user?.token)
        .catch(async error => {
            LogError(`Scoring Rules Update error ${error}`)
        })
    }
    const isScorer = ()=> {
        return (auth?.isAdmin() || selectedCompetition?.Scorer===auth?.user?.userDetails?.userid);
    }

    return (
        <CompetitionsContext.Provider value={
            {
                competitions: competitions,
                competitionsDispatch: competitionsDispatch,
                findCompetition: findCompetition,
                sortedCompetitions: sortedCompetitions,
                visibleCompetitions: visibleCompetitions,
                selectedCompetition: selectedCompetition,
                setCompetition: setCompetition,
                ScoringConfig: scoringconfig,
                saveScoringConfig: saveScoringConfig,
                isScorer: isScorer,
                loading: loading,
            }}>
            {children}
        </CompetitionsContext.Provider>
    )
}

export function useCompetitionsContext() {
    const context = React.useContext(CompetitionsContext);
    if (context === undefined) {
        throw new Error("useCompetitionsContext must be used within a CompetitionsProvider");
    }
    return context;
}

export const useCompetitions = (): CompetitionsContextData=> {
    const context = useCompetitionsContext();
    return context;
}
export const CompetitionsConsumer = CompetitionsContext.Consumer;

export default CompetitionsContext;

