import {  produce } from 'immer';
import {  useState } from 'react';
import { DateTime } from 'luxon';


import { Sector, BarrelSector, FAISector, FinishSector, StartSector } from 'ladderscoring';
import { TaskModel, ITaskPoint, ITurnPoint, DistanceCalcs,  } from 'ladderscoring';

//import { saveFile } from '../lib/SaveFile';
import { API_ENDPOINTS, API_URL, DEFAULT_DATE } from '../Globals';
import { IToken } from '../Auth/Auth';
import { deleteItem, post } from '../services/FetchWrapper';

import { saveFile } from '../lib/SaveFile';
import { Log, LogError } from '../services/Logging';

export enum TMActionType {
    INSERT = 'INSERT',
    DELETE = 'DELETE',
    INSERT_TP = 'INSERT_TP',
    DELETE_TP = 'DELETE_TP',
    UPDATE_TP = 'UPDATE_TP',
    UPDATE_SECTOR = 'UPDATE_SECTOR',
    UPDATE_SECTORS = 'UPDATE_SECTORS',
    UPDATE_DATE = 'UPDATE_DATE',
    UPDATE_TITLE='UPDATE_TITLE',
    UPDATE_ROW='UPDATE_ROW',
    CLEAR_TASK = 'CLEAR_TASK',
    SAVE_TASK = 'SAVE_TASK',
    SAVE_TASK_DB = 'SAVE_TASK_DB',
    LOAD_TASK = 'LOAD_TASK',
    SAVE_ALL_TASKS = 'SAVE_ALL_TASKS',
    LOAD_ALL_TASKS = 'LOAD_ALL_TASKS',
    LOAD_SUBTASKS = 'LOAD_SUBTASKS',
    DELETE_SUBTASKS = 'DELETE_SUBTASKS',
    CLEAR = 'CLEAR',
    SORT = 'SORT',

}
export type TMAction = {
    type: TMActionType;
    payload?: any;
}

export interface Task {
    ID: string | number;
    TaskDate: string;       //ISO Date
    Title: string;
    SubTaskOf?: string | number;
    CompetitionID: number | undefined;
}

export interface TaskTP {
    TaskID: string | number;
    Trigraph: string;     // the Trigraph
    TP: ITurnPoint | undefined;
    TPIndex: number;
    Radius1: number | undefined;
    Angle1 : number | undefined;
    Radius2: number | undefined;
    Angle2: number | undefined;
    Line: boolean;
}

export interface ITaskWithTPs {
    Task: Task,
    TaskTPs: TaskTP[]
}

export const useTaskReducer = (initState: TaskModel[]) => {
    const [state, setState] = useState(initState);

    const dispatchState = async (action: TMAction) => {
        let newState = await asyncTMReducer(state, action);
        setState(newState);
    }
    
    return [state, dispatchState] as const;   // need the 'as const' to get Typescript inferencing working
}

function updateCalcs (tm:TaskModel) {
    DistanceCalcs.updateDistances(tm);     
}

function setTitles (taskpoints: ITaskPoint[]): void {
    taskpoints.forEach((tp, index) => {
        switch (index) {
            case 0:
                tp.title = 'Start';
                break;
            case taskpoints.length - 1:
                tp.title = 'Finish';
                break;
            default:
                tp.title = `TP ${index}`;
                break;
        }
    })
}
function newTaskPoint(title: string, TP: ITurnPoint | undefined | null,reached: boolean,sector: Sector): ITaskPoint {
    return (
        {
            title: title,
            TP: TP ?? null,
            sector: sector,
            legDistance: undefined,
            sectorDistance: undefined,
        }
    )
}


export function createTask(taskdef:ITaskWithTPs,  turnpoints: ITurnPoint[],  taskID?:string | number, ): TaskModel {
    let task = new TaskModel();

    if (taskdef.TaskTPs.length > 0) {
        task.turnpoints.length = 0;
        taskdef.TaskTPs.forEach((taskpoint) => {
            // create a new TP with the data
            // Need to look up the TP in the turnpoints list ...
            let tp = turnpoints.find(t=>t.Trigraph === taskpoint.Trigraph)

            let newPoint = newTaskPoint(
                '',             // Title is set later
                tp,
                false,
                new Sector(
                    taskpoint.Radius1, 
                    taskpoint.Angle1,
                    taskpoint.Radius2,
                    taskpoint.Angle2,
                    taskpoint.Line
                )
            );
            task.turnpoints.push(newPoint);
        })
        task.TaskID = taskID;
        task.CompetitionID = taskdef.Task.CompetitionID;
        setTitles(task.turnpoints);
        updateCalcs(task);
    }
    return task;
}

function makeITask(task:TaskModel):ITaskWithTPs {
    let result: ITaskWithTPs = {
        Task: {
            ID: task.TaskID ?? 0,     // will be filled in after save to server
            CompetitionID: task.CompetitionID,
            TaskDate: task.TaskDate ?? DEFAULT_DATE,
            Title: task.Title,
            SubTaskOf: task.SubTaskOf,
        },
        TaskTPs: []
    };
    task.turnpoints.forEach((tp, index)=> {
        if(tp.TP) {
            result.TaskTPs.push(
                {
                    TaskID: task.TaskID ?? 0,
                    Trigraph: tp.TP.Trigraph,
                    TP: undefined,          // this gets filled in on load back from server
                    TPIndex: index,
                    Radius1: tp.sector.radius1,
                    Angle1 : tp.sector.angle1,
                    Radius2: tp.sector.radius2,
                    Angle2: tp.sector.angle2,
                    Line: tp.sector.line
                }
            );
        }
        else {
            throw new Error(`Saving task ${task.Title}, TP ${index} has an undefined TP` )
        }
    });
    
    return result;
}

function loadTasks(tasks: TaskModel[], tasklist: ITaskWithTPs[], turnpoints: ITurnPoint[], clearTasks:boolean) {

    if (clearTasks) {
        tasks.length = 0;
    }
        tasklist.forEach(taskdef => {

        // find existing task
        let idx = tasks.findIndex(t => { return t.TaskID === taskdef.Task.ID })
        if (idx !== -1) {
            // update existing

            let tsk = createTask(taskdef, turnpoints, tasks[idx].TaskID);
            tasks[idx] = tsk;
            tasks[idx].TaskDate = DateTime.fromISO(taskdef.Task.TaskDate).toISODate()!;
            tasks[idx].Title = taskdef.Task.Title;
            tasks[idx].SubTaskOf = taskdef.Task.SubTaskOf;
        }
        else {
            // create new
            let task = createTask(taskdef, turnpoints, taskdef.Task.ID ?? 0);
            task.TaskDate = DateTime.fromISO(taskdef.Task.TaskDate).toISODate()!;
            task.TaskID = taskdef.Task.ID;
            task.Title = taskdef.Task.Title;
            task.SubTaskOf = taskdef.Task.SubTaskOf;
            tasks.push(task);
        }
    });
    
    sortTasks(tasks);
}

function sortTasks(tasks: TaskModel[]) {

    tasks.sort((t1, t2) => {
        if ((t1?.TaskDate ?? DEFAULT_DATE) > (t2?.TaskDate ?? DEFAULT_DATE)) return 1;
        else if ((t1?.TaskDate ?? DEFAULT_DATE) < (t2?.TaskDate ?? DEFAULT_DATE)) return -1;
        else {
            if ((t1?.Title ?? '') > (t2?.Title ?? '') ) return 1;
            else if ((t1?.Title ?? '' )< (t2?.Title ?? '' )) return -1;
        }        
        return 0;
    })
}

async function removeSubTasks(tasks: TaskModel[], taskid: string| number, token:IToken) {
    // remove the subTasks of the specified task
    let subtasks = tasks.filter(t => { return t.SubTaskOf === taskid });
        subtasks.map(async task => {
            let idx = tasks.findIndex(t => { return t.TaskID === task.TaskID });
            if (idx !== -1) {
                let taskid = tasks[idx].TaskID;
                Log(`removeSubTasks: removing id ${taskid}`)
                tasks.splice(idx, 1);
                await deleteTask(taskid, token);
            }
        })        
}

async function  postTask  (task: ITaskWithTPs, token:IToken) {  
    return post<ITaskWithTPs>(`${API_URL}/${API_ENDPOINTS.SAVETASK}`, task, token)
    .catch(async error => {
        LogError(`Task Update error ${error}`)
    })
}

async function deleteTask  (taskid: string|number|undefined , token:IToken) {
    if (taskid) {
        Log(`Deleting task ID ${taskid} from DB`)
        return deleteItem(`${API_URL}/${API_ENDPOINTS.DELETETASK}/${taskid}`, token)
        .catch(async error => {
            LogError(`Task Delete error ${error}`)
        })
    }
}

async function insertTask (task:TaskModel, token:IToken) {
    // inserts task to the DB then rurns the created ID
    return (postTask(makeITask(task), token)
        .then(resp=> {
            if (resp) {
                let newtask = resp.parsedBody       // this now has the correct ID
                if (newtask) {
                    return newtask.Task.ID;                
                }
            }
        })
        .catch(e => {
            Log(`TaskModelReducer: insertTask error: ${(e as Error).message}`)
            return 0
    }))
}
export async function asyncTMReducer(tasks: TaskModel[], action: TMAction): Promise<TaskModel[]> {
    
        return produce(tasks, async (drafttasks) => {
            //Log(`tmReducer: Action: ${action.type} with payload `, action.payload)

            switch (action.type) {
                case TMActionType.INSERT: {
                    //payload is {value: task,  token: auth?.user?.token}
                    let task = action.payload.value as TaskModel;
                    // convert to an ITaskwithTPs & send to server...then update the local ID
                    await insertTask(task, action.payload.token)
                        .then(newid => {
                            task.TaskID=newid
                            drafttasks.unshift(task);
                        })
                    break;
                }
                case TMActionType.DELETE: {
                    //payload is {ID: TaskID, token: token}
                    let idx = drafttasks.findIndex(t => { return t.TaskID?.toString() === action.payload.ID.toString() })
                    if (idx !== -1) {
                        deleteTask(action.payload.ID, action.payload.token);                        
                        // also delete any subtasks
                        drafttasks.splice(idx, 1);
                        await removeSubTasks(drafttasks, action.payload.ID, action.payload.token);                        
                    }
                    break;
                }             

                case TMActionType.INSERT_TP: {
                    // insert a TP after the index given in the payload
                    //payload is {TaskID: string, index: number, token: IToken}
                    let idx = drafttasks.findIndex(t => { return t.TaskID === action.payload.TaskID });
                    if (idx !== -1) {
                        let tpindex = action.payload.index;
                        let title = `TP ${tpindex}`;
                        let sector: Sector;

                        switch (tpindex) {
                            case 0:
                                sector = StartSector();
                                break;
                            case drafttasks[idx].turnpoints.length:
                                sector = FinishSector();
                                break;
                            default:
                                sector = BarrelSector();
                        }
                        let tp: ITaskPoint = newTaskPoint(title, null, false, sector);

                        drafttasks[idx].insertTP(tp, action.payload.index);
                        setTitles(drafttasks[idx].turnpoints);
                        updateCalcs(drafttasks[idx]);
                    }
                    break;
                }

                case TMActionType.DELETE_TP: {
                    //payload is {TaskID: string, index: number}
                    let idx = drafttasks.findIndex(t => { return t.TaskID === action.payload.TaskID });
                    if (idx !== -1) {
                        drafttasks[idx].deleteTP(action.payload.index);
                        setTitles(drafttasks[idx].turnpoints);
                        updateCalcs(drafttasks[idx]);
                    }
                    break;
                }
                case TMActionType.UPDATE_TP: {
                    // payload is {TaskID: string, index: number, turnpoint: TP}
                    let idx = drafttasks.findIndex(t => { return t.TaskID === action.payload.TaskID });
                    if (idx !== -1) {
                        let lastIndex = drafttasks[idx].turnpoints.length - 1;
                        drafttasks[idx].turnpoints[action.payload.index].TP = action.payload.turnpoint;
                        if (action.payload.index === 0 && drafttasks[idx].turnpoints[lastIndex].TP === null) {
                            drafttasks[idx].turnpoints[lastIndex].TP = action.payload.turnpoint;
                        }
                        updateCalcs(drafttasks[idx]);
                    }
                    break;
                }
                case TMActionType.UPDATE_SECTOR: {
                    // payload is {TaskID: string, index: number, sector: sector}
                    let idx = drafttasks.findIndex(t => { return  t.TaskID === action.payload.TaskID });
                    if (idx !== -1) {
                        drafttasks[idx].turnpoints[action.payload.index].sector = action.payload.sector;
                        // check if there are any subtasks of this task, and if so maybe update them
                        let subtasks= drafttasks.filter(t=>t.SubTaskOf===drafttasks[idx].TaskID);
                        let isStartSector = action.payload.index===0;
                        let isFinishSector = action.payload.index === drafttasks[idx].turnpoints.length-1;

                        subtasks.forEach(subtask => {
                            // Does the specified index exist in the subTask?                            
                            if (isStartSector) {
                                Log(`TaskModelReducer: Updating Start Sector for task ${subtask.description}`);
                                subtask.turnpoints[0].sector = action.payload.sector;                            
                            }
                            else if (isFinishSector) {
                                Log(`TaskModelReducer: Updating Finish for task ${subtask.description}`);
                                subtask.turnpoints[subtask.turnpoints.length-1].sector = action.payload.sector; 
                            }
                            // it's a turnpoint. If the index exists in the subtask, update it
                            else if (action.payload.index < subtask.turnpoints.length-1) {
                                Log(`TaskModelReducer: Updating sector for TP ${action.payload.index} in task ${subtask.description}`);
                                subtask.turnpoints[action.payload.index].sector = action.payload.sector;
                            }

                        })


                        updateCalcs(drafttasks[idx])
                    }
                    break;
                }
                case TMActionType.UPDATE_SECTORS: {
                    // update all sectors to FAI or Barrels
                    // payload is {TaskID: string, , FAI: boolean}
                    let idx = drafttasks.findIndex(t => { return  t.TaskID === action.payload.TaskID });
                    if (idx !== -1) {
                        drafttasks[idx].turnpoints = drafttasks[idx].turnpoints.map((tp, index) => {
                            if (index !== 0 && index !== drafttasks[idx].turnpoints.length - 1) { // only change non start non finish
                                tp.sector = action.payload.FAI ? FAISector() : BarrelSector();
                            }
                            return tp;
                        })
                        updateCalcs(drafttasks[idx])
                    }
                    break;
                }

                case TMActionType.UPDATE_DATE: {
                    //payload is {TaskID: string, date: ISO Date}
                    let idx = drafttasks.findIndex(t => { return t.TaskID?.toString() === action.payload.TaskID });
                    if (idx !== -1) {
                        drafttasks[idx].TaskDate=action.payload.date;
                    }
                    break;
                }
                case TMActionType.UPDATE_TITLE: {
                    //payload is {TaskID: string, title: string}
                    let idx = drafttasks.findIndex(t => { return t.TaskID?.toString() === action.payload.TaskID });
                    if (idx !== -1) {
                        drafttasks[idx].Title= action.payload.title;
                    }
                    break;
                }
                case TMActionType.UPDATE_ROW: {
                    let idx = drafttasks.findIndex(t => { return t.TaskID?.toString() === action.payload.TaskID });
                    if (idx !== -1) {
                        let keys = Object.keys(action.payload.value);
                        keys.forEach(key=> {
                            switch (key) {
                                case 'Title' : {
                                    drafttasks[idx].Title=action.payload.value[key]
                                    break;
                                }
                                case 'TaskDate' : {
                                    drafttasks[idx].TaskDate=action.payload.value[key]
                                    break;
                                }
                            }
                        });
                    }

                    break;
                }
                case TMActionType.CLEAR_TASK: {
                    // payload is {TaskID}
                    let idx = drafttasks.findIndex(t => { return  t.TaskID === action.payload.TaskID });
                    if (idx !== -1) {
                        drafttasks[idx] = new TaskModel();
                    }
                    break;
                }
                case TMActionType.CLEAR: {
                    drafttasks.length=0;
                    break;
                }
                case TMActionType.SAVE_TASK: {

                    // Save the current task definition to a JSON file...
                    // action.payload is {TaskID: string, token:IToken}
                    if (action.payload.name !== '') {
                        let idx = drafttasks.findIndex(t => { return t.TaskID === action.payload.TaskID });
                        if (idx !== -1) {
                            
                            let tasklist: ITaskWithTPs[] = [];
                            tasklist.push(makeITask( tasks[idx]));                            
                            saveFile(tasks[idx].Title, JSON.stringify({ Tasks: tasklist}));
                        }
                    }
                    break;
                }
                case TMActionType.SAVE_TASK_DB: {
                    // payload is {TaskID: string, token:IToken}
                    let idx = drafttasks.findIndex(t => { return t.TaskID === action.payload.TaskID });
                    if (idx !== -1) {
                        // remove any existing SubTasks
                        //await removeSubTasks(drafttasks, action.payload.TaskID, action.payload.token);    
                        await postTask(makeITask(drafttasks[idx]), action.payload.token);
                    }
                    break;
            }
                case TMActionType.SAVE_ALL_TASKS: {
                        // payload is {token: auth?.user?.token}
                        drafttasks.forEach(async (task) =>  {
                            if (task.TaskID !== 0) {
                                await postTask(makeITask(task), action.payload.token);
                            }
                        });                    
                    break;
                }

                case TMActionType.LOAD_TASK: {
                    // Load the currect task from a JSON file
                    // action.payload is value: {Tasks: ITaskWithTPs, Turnpoints: turnpoints}
                    // If TaskID exists, overwrite. Otherwise add a new task
                    
                    let tasklist: ITaskWithTPs[] = action.payload.Tasks;
                    loadTasks(drafttasks, tasklist, action.payload.Turnpoints, false);
                    break;
                }

                case TMActionType.LOAD_ALL_TASKS: {
                    // action.payload is {Tasks: ITaskWithTPs[], Turnpoints: ITurnpoint[]}
                    let tasklist: ITaskWithTPs[] = action.payload.Tasks;
                    loadTasks(drafttasks, tasklist, action.payload.Turnpoints, true);
                    break;
                }
                
                case TMActionType.LOAD_SUBTASKS: {
                    // action.payload is {TaskID: token:IToken}
                    // remove existing subtasks...
                    //Log(`LOAD_SUBTASKS starting`)
                    await removeSubTasks(drafttasks, action.payload.TaskID, action.payload.token);    // remove existing
                    //Log(`LOAD_SUBTASKS: Subtasks Removed`)
                    let idx = drafttasks.findIndex(t => { return t.TaskID === action.payload.TaskID });
                    if (idx !== -1) {
                        let subtasks = drafttasks[idx].SubTasks;
                        //Log(`LOAD_SUBTASKS: Generated subtasks are `, subtasks)
                        await Promise.all(
                            subtasks.map( async(subtask) => {
                                subtask.TaskDate = drafttasks[idx].TaskDate;
                                 return insertTask(subtask, action.payload.token)
                                    .then(async (newid) => {
                                        subtask.TaskID=newid;
                                        //Log(`LOAD_SUBTASKS: adding task `, subtask)
                                        drafttasks.push(subtask);
                                        await postTask(makeITask(subtask), action.payload.token);
                                    })
                            })
                        )
                        sortTasks(drafttasks);
                        }
                        //Log(`LOAD_SUBTASKS complete`)
                    break;
                }
                case TMActionType.DELETE_SUBTASKS: {
                    // action.payload is {TaskID: token:IToken}
                    await removeSubTasks(drafttasks, action.payload.TaskID, action.payload.token);    // remove existing
                    break;
                }
                case TMActionType.SORT : {
                //payload is {SortCol: string, SortOrder 'asc' | 'desc'}
                let sortcol = action.payload.SortCol;
                let sortorder = action.payload.SortOrder;


                if (sortcol !== '') {
                    switch (sortcol) {

                        case 'TaskID': {
                            drafttasks.sort((a, b) => {
                                return sortorder === 'asc' ?
                                    ((tasks.find(t => t.TaskID === a.TaskID)?.Title) ?? '').localeCompare((tasks.find(t => t.TaskID === b.TaskID)?.Title) ?? '')
                                    :
                                    ((tasks.find(t => t.TaskID === b.TaskID)?.Title) ?? '').localeCompare((tasks.find(t => t.TaskID === a.TaskID)?.Title) ?? '')
                            })

                            break;
                        }
                        case 'TaskDate': {
                            drafttasks.sort((a, b) => {
                                return sortorder === 'asc' ? (a.TaskDate ?? '').localeCompare((b.TaskDate ?? '')) : (b.TaskDate ?? '').localeCompare((a.TaskDate ??''))
                            })
                            break;
                        }


                        default:  {
                            sortTasks(drafttasks);
                            break;
                        }

                    }
                }
                break;
                }

                default: {
                    throw new Error(`Task Reducer: Unrecognised Action Type ${action.type}`)
                }

            }
        });
    }



