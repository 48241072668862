import { useState } from 'react';
import { USER_DETAILS } from '../Globals';
// eslint-disable-next-line
import { Log } from '../services/Logging';
import { IUser } from './Auth';

const store = localStorage; // or sessionStorage
export const useUser = (initialValue?: IUser):[user:IUser | null, setUser: (user:IUser | null)=>void, ] =>
{
    const getUser = (): IUser | null => {
        const userString = store.getItem(USER_DETAILS);
        //Log(`getUser: got ${userString} from storage`)
        if (userString !== null) {
            return JSON.parse(userString)
        }
        else {
            return null
        }
    }
    const [user, setUser] = useState<IUser | null>(getUser());

    if (initialValue !== undefined) {
        setUser(initialValue)
    }

    return [user, setUser] 
    
}