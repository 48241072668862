// Competitor
import {  produce } from 'immer';
import {  useState,  } from 'react';
import { IToken } from '../Auth/Auth';
import { API_ENDPOINTS, API_URL } from '../Globals';
import { deleteItem, post } from '../services/FetchWrapper';
import { Log, LogError } from '../services/Logging';


export interface ICompetitor extends Record<string, any> {
    //ID: string | number | undefined;
    ID:  number;
    CompetitionID?: string | number;
    CompClassID?: number;
    PilotName?: string ;
    Club?: string ;
    CompID?: string;
    GliderCode?: number;
}


export enum CompetitorActionType {
    INSERT = 'INSERT',
    DELETE = 'DELETE',
    UPDATE_NAME = 'UPDATE_NAME',
    UPDATE_CLUB = 'UPDATE_CLUB',
    UPDATE_CID = 'UPDATE_CID',
    UPDATE_GLIDER = 'UPDATE_GLIDER',
    UPDATE_ALL = 'UPDATE_ALL',
    UPDATE_ROW = 'UPDATE_ROW',
    SAVE = 'SAVE',
    LOAD = 'LOAD',
    LOAD_WITH_SAVE = 'LOADW_WITH_SAVE',
    SORT = 'SORT',
    CLEAR = 'CLEAR',
}

export type CompetitorAction = {
    type: CompetitorActionType;
    payload?: any;
}

export const useCompetitorReducer = (initState: ICompetitor[]) => {
    const [state, setState] = useState(initState);

    const dispatch = async (action: CompetitorAction) => {
        let newState = await competitorReducer(state, action);
        setState(newState);
    }

    return [state, dispatch] as const;   // need the 'as const' to get Typescript inferencing working
}

const postCompetitor = async (comp: ICompetitor, token:IToken) => {
    return post<ICompetitor>(`${API_URL}/${API_ENDPOINTS.SAVECOMPETITOR}`, comp, token)
    .catch(async error => {
        LogError(`Competitor Update error ${error}`)
    })
}

const postCompetitors = async (comps: ICompetitor[], token:IToken) => {
    return post<ICompetitor[]>(`${API_URL}/${API_ENDPOINTS.SAVECOMPETITORS}`, comps, token)
    .catch(async error => {
        LogError(`Competitor Update error ${error}`)
    })
}

const deleteCompetitor = async (compid: string|number|undefined , token:IToken) => {
    Log(`deleteCompetitor ${compid}`)
    if (compid) {
    return deleteItem<ICompetitor>(`${API_URL}/${API_ENDPOINTS.DELETECOMPETITOR}/${compid}`, token)
    .catch(async error => {
        LogError(`Competitor Delete error ${error}`)
    })
    }
}

export async function competitorReducer(competitors: ICompetitor[], action: CompetitorAction):Promise<ICompetitor[]> {

    return await produce(competitors, async (draftCompetitors) => {
        //Log(`competitorReducer: ${action.type} with payload `, action.payload);
        switch (action.type) {

            case CompetitorActionType.INSERT:
                //payload is {value: comp, token: auth?.user?.token}
                let comp = action.payload.value as ICompetitor;
                await postCompetitor(comp, action.payload.token)
                    .then(resp=> {
                        if (resp) {
                            let newcomp = resp.parsedBody       // this now has the correct ID
                            if (newcomp) {
                                draftCompetitors.unshift({...comp, ID: newcomp.ID});
                            }
                        }
                    })

                break;
            case CompetitorActionType.DELETE: {
               //payload is { ID, token},
                let idx = draftCompetitors.findIndex(c => { return c.ID === action.payload.ID })
                if (idx !== -1) {
                    let compid = draftCompetitors[idx].ID;
                    await deleteCompetitor(compid, action.payload.token)
                    draftCompetitors.splice(idx, 1);
                }
                break;
            }
            case CompetitorActionType.UPDATE_NAME:
                //payload is {index: which to rename, name: string
                draftCompetitors[action.payload.index].PilotName = action.payload.value;
                break;

            case CompetitorActionType.UPDATE_CLUB:
                //payload is {index: which to rename, Club: string
                draftCompetitors[action.payload.index].Club = action.payload.value;
                break;

            case CompetitorActionType.UPDATE_CID:
                //payload is {index: which to rename, Club: string
                draftCompetitors[action.payload.index].CompetitionID = action.payload.value;
                break;

            case CompetitorActionType.UPDATE_GLIDER:
                //payload is {CompetitorID: which to update, glider: number
                let idx = draftCompetitors.findIndex(c => { return c.ID === action.payload.CompetitorID })
                if (idx !== -1) {
                    draftCompetitors[idx].GliderCode = action.payload.glidercode;
                }
                break;

            case CompetitorActionType.SAVE: {
                draftCompetitors.forEach(comp => {
                    postCompetitor(comp, action.payload.token)
                })
                break;
            }

            case CompetitorActionType.LOAD: {
                // payload is { value: dbCompetitor[] }
                draftCompetitors.length = 0;

                let list = action.payload.value as ICompetitor[]
                list.forEach((competitor) => {
                    draftCompetitors.push(competitor);
                })
                break;
            }

            case CompetitorActionType.LOAD_WITH_SAVE: {
                // payload is { value: ICompetitor[], token }
                draftCompetitors.length = 0;
                let list = action.payload.value as ICompetitor[]
                // write back to db...

                
                //let promises: Array<Promise<void | HttpResponse<ICompetitor>>> = [];
                await postCompetitors(list, action.payload.token);

                
                // list.forEach(async (comp) => {
                //     let newcomp = await postCompetitor(comp, action.payload.token);
                //     Log(`${comp.PilotName} done, result `, newcomp?.parsedBody)
                //     if (newcomp && newcomp.parsedBody) {
                //         draftCompetitors.unshift({...comp, ID: newcomp.parsedBody.ID});
                //     }
                    // promises.push(
                    //     postCompetitor(comp, action.payload.token)
                    //     .then(resp=> {
                    //         Log(`PostCompetitor ${comp.PilotName} done, result `,resp)
                    //         if (resp) {
                    //             let newcomp = resp.parsedBody       // this now has the correct ID
                    //             if (newcomp) {
                    //                 draftCompetitors.unshift({...comp, ID: newcomp.ID});
                    //             }
                    //         }
                    //     })
                    // )
                    //});
                // wait for all the updates to be complete...
                //await Promise.all(promises);
                break;
            }
            case CompetitorActionType.UPDATE_ROW: {
                //payload is {ID: changedID, value:changedvalue, token: auth?.user?.token},
                let idx = draftCompetitors.findIndex(c => { return c.ID?.toString() === action.payload.ID })
                if (idx !== -1) {
                    draftCompetitors[idx] = { ...draftCompetitors[idx], ...action.payload.value }
                    postCompetitor(draftCompetitors[idx], action.payload.token)
                }
                break;
            }
            case CompetitorActionType.UPDATE_ALL: {
                //payload is new Competitors
                draftCompetitors.length = 0;
                (action.payload as ICompetitor[]).forEach(c => draftCompetitors.push(c));
                break;
            }

            case CompetitorActionType.SORT: {
                //payload is {SortCol: string, SortOrder 'asc' | 'desc'}
                let sortcol = action.payload.SortCol;
                let sortorder = action.payload.SortOrder;
                if (sortcol !== '') {
                    draftCompetitors.sort((a, b) => {
                        return sortorder === 'asc' ? a[sortcol].localeCompare(b[sortcol]) : b[sortcol].localeCompare(a[sortcol])
                    })
                }         
                break;
            }

            case CompetitorActionType.CLEAR: {
                draftCompetitors.length = 0;
                break;
            }

            default:{
                throw new Error(`Competitor Reducer: Unrecognised Action Type ${action.type}`)
                }
        }
    })
}
