import { IScoringConfig } from "ladderscoring";

//export const AUTH_API_HOST = 'https://lsapi.bgaladder.net'
export const AUTH_API_HOST =  process.env.NODE_ENV === 'production' ? 
    process.env.REACT_APP_ENV==='staging' ?
        'https://lsapistaging.bgaladder.net' 
        :
        'https://lsapi.bgaladder.net' 
    :          
    'https://localhost:44352'


//export const API_HOST = 'https://lsapi.bgaladder.net'
export const API_HOST =  process.env.NODE_ENV === 'production' ? 
    process.env.REACT_APP_ENV==='staging' ?
        'https://lsapistaging.bgaladder.net' 
        :
        'https://lsapi.bgaladder.net'
    :          
    'https://localhost:44352'


export const API_URL = `${API_HOST}/API`
export const AUTH_API_URL = `${AUTH_API_HOST}/API`
export const GLANA_URL =  `https://glana.bgaladder.net/?bgaBaseUrl=${encodeURI(API_HOST)}&bgaUserid=0&bgaID=`

// these are endpoints for the ScoringAPI

export enum API_ENDPOINTS {
    USERS = 'USERS',
    SAVEUSER = 'SAVEUSER',
    DELETEUSER = 'DELETEUSER',

    GETPREFS = 'GETPREFS',
    SAVEPREFS = 'SAVEPREFS',

    COMPETITIONS = 'COMPETITIONS',
    SCORERCOMPS = 'SCORERCOMPS',
    SAVECOMPETITION = 'SAVECOMPETITION',
    DELETECOMPETITION = 'DELETECOMPETITION',
    SCORINGCONFIG = `SCORINGCONFIG`,
    WINDS = 'CONTESTWIND',
    HEIGHTS = 'CONTESTHEIGHTS',

    COMPETITORS = 'COMPETITORS',
    SAVECOMPETITOR = 'SAVECOMPETITOR',
    SAVECOMPETITORS = 'SAVECOMPETITORS',
    DELETECOMPETITOR = 'DELETECOMPETITOR',

    TASKS = 'TASKS',        // key is CompetitionID
    SAVETASK = 'SAVETASK',
    DELETETASK = 'DELETETASK',
    DELETESUBTASKS = 'DELETESUBTASKS', // key is TaskID
    TASKWITHTPS = 'TASKWITHTPS',    // key is taskID, returns {task: task, tasktps: tasktps}
    COMPTASKS = 'COMPTASKS',       // key is compid, returns array of {task: task, tasktps: tasktps}
    TASKTPS = 'TASKTPS',        // key is TaskID
    SAVETASKTP = 'SAVETASKTP',
    DELETETASKTP = 'DELETETASKTP',  // Keys are TaskID, Index

    FLIGHTS = 'FLIGHTS', // key is CompetitionID
    SAVEFLIGHT = 'SAVEFLIGHT',
    DELETEFLIGHT = 'DELETEFLIGHT',   //key is flight id

    LOGFILE = 'LOGFILE',    // key is logfileID
    SAVELOGFILE = 'SAVELOGFILE',
    DELETELOGFILE = 'DELETELOGFILE',
    IGCFILE = 'LOGFILECONTENTS',

    COMPCLASSES = 'COMPCLASSES',
    SAVECOMPCLASS = 'SAVECOMPCLASS',
    DELETECOMPCLASS = 'DELETECOMPCLASS',

    SERILOGS = 'SERILOGS',
    CLEARLOG = 'CLEARSERILOG',
    SERIAUDITLOGS = 'SERIAUDITLOGS',
    CLEARAUDITLOG = 'CLEARSERIAUDITLOG',

    GETELEVATION = 'GETELEVATION',

    GETVARIABLE = 'GETVARIABLE',
    SETVARIABLE = 'SETVARIABLE',
    RESULTSSAVED = 'RESULTSSAVED',
    GETRESULTSCOUNT = 'GETRESULTSCOUNT'
    
}

export const REFRESH_INTERVAL = 10000;      // interval to poll for new results

export const METRE2FOOT = 3.2808399;
export const KM2MILES = 0.62137119224;
export const MPS2KNOT = 1.9426025694;
export const MPS2FPM = 196.8503937;
export const KM2NM = 0.53961;
export const DEG2RAD = Math.PI / 180;

export const MAX_VALID_WIND = 30;       // wind strengths above this are likely invalid

export const TASKLETTERS = 'ABCDEFGHIJ';

export const DEFAULT_DATE='2000-01-01'

// keys for local/session storage

export const APP_NAME = 'LadderScoring'
export const USER_DETAILS =  `${APP_NAME}_User`;

export const LASTDATEKEY = `${APP_NAME}_lastdate`;
export const DEFAULT_DATE_FORMAT = { month: 'long', day: 'numeric', year: 'numeric' } as const;

export const FLIGHTS_WIDTHS = `${APP_NAME}_FlightsColumnWidths`;
export const FLIGHTS_COLUMNORDER = `${APP_NAME}_FlightsColumnOrder`;

export const RESULTS_WIDTHS = `${APP_NAME}_ResultsColumnWidths`;
export const RESULTS_COLUMNORDER = `${APP_NAME}_ResultsColumnOrder`;

export const COMPETITIONS_WIDTHS = `${APP_NAME}_CompetitionsColumnWidths`;
export const COMPETITIONS_COLUMNORDER = `${APP_NAME}_CompetitionsColumnOrder`;

export const COMPETITORS_WIDTHS = `${APP_NAME}_CompetitorsColumnWidths`;
export const COMPETITORS_COLUMNORDER = `${APP_NAME}_CompetitorsColumnOrder`;

export const SCORING_WIDTHS = `${APP_NAME}_ScoringColumnWidths`;
export const SCORING_COLUMNORDER = `${APP_NAME}_ScoringColumnOrder`;

export const TASKS_WIDTHS = `${APP_NAME}TasksColumnWidths`;
export const TASKS_COLUMNORDER = `${APP_NAME}TasksColumnOrder`;

export const DEFAULT_SCORING_CONFIG:IScoringConfig = {
    CompetitionID:0,
    DistancePoints:5,
    SpeedPoints:.05,
    MinimumSpeed:50,
    CompletionFactor:1.1,
    UseMinDistance:true,
    UseTaskDistance:true,
    CompleteForSpeed:true,
}

export const DNF_TEXT='Not Scored'