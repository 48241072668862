
import React from 'react'

export default function ShowEnvironment() {

    return (
        <span className='small'>
            {
            process.env.NODE_ENV === 'production' ? 
                process.env.REACT_APP_ENV==='staging' ?
                    'Staging' 
                    :
                    ''          // don't show anything if in Production env.
                :          
                'Development'
            }
        </span>
        )
}