import * as React from "react";

import {  useEffect,  } from 'react';

//import { StaticContext } from 'react-router';
import { Navigate,  } from 'react-router-dom';
import { useCompetitions } from "../Context/CompetitionsContext";

import { useAuth } from './AuthProvider';

export default function Logout() {

    const auth = useAuth();
    const {setCompetition} = useCompetitions();

    useEffect(() => {
        auth?.signOut()
        .then(()=>setCompetition(undefined,false))
    })
    return (
            <Navigate to={ '/'} />
            )
    
}