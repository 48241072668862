import * as React from 'react';
import {useState} from 'react'
import { Collapse, Container, Navbar, NavbarToggler, NavItem, NavLink } from 'reactstrap';
import { Link } from 'react-router-dom';
import './NavMenu.css';
import { useAuth } from '../Auth/AuthProvider';
import { useUsers } from '../Context/UsersContext';
import CompetitionSetter from './Selectors/CompetitionSetter';
import { Row, Col } from 'react-bootstrap';
import ShowEnvironment from './Utility/ShowEnvironment';


interface INavMenuProps { }

const NavMenu: React.FC<React.PropsWithChildren<INavMenuProps>> = props => {

    const auth = useAuth();

    const {userName} = useUsers()

    const [collapsed, setCollapsed] = useState(true);

    const toggleNavbar = () => {
        setCollapsed(!collapsed);
    }
    return (
        <header>
            <Navbar className="navbar-expand-sm navbar-toggleable-sm ng-white border-bottom box-shadow mb-3" light>
                <Container>
                    <Row>
                        {/* <Col xs={5} sm={3}>
                        <NavbarBrand tag={Link} to="/">
                            Competition
                        </NavbarBrand>
                        </Col> */}
                        <Col xs={12} sm={4}>
                            <CompetitionSetter size='sm'/>
                        </Col>
                        <Col xs={12} sm={4} className='text-end'>
                            <ShowEnvironment />
                        </Col>
                    </Row>
                    <NavbarToggler onClick={toggleNavbar} className="me-2" />
                    <Collapse className="d-sm-inline-flex " isOpen={!collapsed} navbar>
                        <ul className="navbar-nav flex-grow-1">
                            <NavItem onClick={()=>{setCollapsed(true)}}>
                                <NavLink tag={Link} className="text-dark" to="/">Home</NavLink>
                            </NavItem>
                            <NavItem  onClick={()=>{setCollapsed(true)}}>
                                <NavLink tag={Link} className="text-dark" to="/Results">Results</NavLink>
                            </NavItem>
                            <NavItem  onClick={()=>{setCollapsed(true)}}>
                                <NavLink tag={Link} className="text-dark" to="/Competitors">Competitors</NavLink>
                            </NavItem>
                            <NavItem  onClick={()=>{setCollapsed(true)}}>
                                <NavLink tag={Link} className="text-dark" to="/Flights">Flights</NavLink>
                            </NavItem>

                            {auth?.isAuthenticated() ?                              
                            <>
                                <NavItem  onClick={()=>{setCollapsed(true)}}>
                                    <NavLink tag={Link} className="text-dark" to="/Competitions">Comps</NavLink>
                                </NavItem>

                                <NavItem  onClick={()=>{setCollapsed(true)}}>
                                    <NavLink tag={Link} className="text-dark" to="/Tasks">Tasks</NavLink>
                                </NavItem>

                                <NavItem  onClick={()=>{setCollapsed(true)}}>
                                    <NavLink tag={Link} className="text-dark" to="/Scoring">Scoring</NavLink>
                                </NavItem>

                            </>
                                 :
                                null
                            }
                            {
                                auth?.isAdmin() ? 
                                <>
                                    <NavItem>
                                        <NavLink tag={Link} className="text-dark" to="/Admin">Admin</NavLink>
                                    </NavItem>
                                    <NavItem>
                                        <NavLink tag={Link} className="text-dark" to="/Test">Test</NavLink>
                                    </NavItem>
                                </>
                                    :
                                    null
                            }
                            </ul>
                            <ul className="navbar-nav ml-auto">
                                {auth?.isAuthenticated() ?
                                    (
                                        <>

                                        <NavItem>
                                            <NavLink tag={Link} className="text-dark" to="/Profile">{userName(auth.user?.userDetails?.userid) } </NavLink>
                                        </NavItem>
                                        <NavItem>
                                            <NavLink tag={Link} className="text-dark" to="/logout">Log Out</NavLink>
                                        </NavItem>

                                        </>
                                    )
                                    :
                                    (
                                        <NavItem>
                                            <NavLink tag={Link} className="text-dark" to="/login">Scorer Log In</NavLink>
                                        </NavItem>
                                    )
                                }
                            </ul>

                    </Collapse>
                </Container>
            </Navbar>
        </header>
    );
}
export default NavMenu


