// User profile management
import * as React from 'react';
import {   useState, useEffect } from 'react';

import { Row, Col, Button, Form, Label,  } from 'reactstrap';
import { useWarningDialog } from '../../../Context/ConfirmDialogContext';
import { dbUser } from '../../../models/User';
import { EditText } from 'react-edit-text';
import 'react-edit-text/dist/index.css';
import DraggableModal from '../../Utility/DraggableModal';

interface IProps {
    show: boolean;
    user: dbUser;
    handleHide: ()=>void;
    handleSave: (user:dbUser)=>void;    
}
export default function UserProfileDialog(props:IProps) {
    const [user, setUser] = useState<dbUser>(props.user);
    const {showWarning} = useWarningDialog();

    useEffect(()=>{
        setUser(props.user)
    },[props.user])

    return (
        <DraggableModal 
        key ='LoginModal'
        show={props.show}
            onClose={props.handleHide}
            header={<span>{`Profile for ${user?.Forename} ${user?.Surname}`}</span> }
            body={
                <Form>
                <Row className='top-buffer-small'>
                    <Col xs={4}>
                        <Label>Forename</Label>
                    </Col>
                    <Col xs={8}>
                        <EditText
                            inline
                            placeholder="Forename"
                            defaultValue={user?.Forename ?? ''}
                            onSave={(result) => {
                                if (result.value !== '') {
                                    setUser({...user, Forename: result.value })
                                }
                                else {
                                    showWarning({title: 'Forename is required', message: 'Forename cannot be blank', closeTimeout:5})
                                }
                            }}
                        />                                                               
                    </Col>
                </Row>
                <Row className='top-buffer'>
                    <Col xs={4}>
                        <Label>Surname</Label>
                    </Col>
                    <Col xs={8}>
                    <EditText
                            inline
                            placeholder="Surname"
                            defaultValue={user?.Surname ?? ''}
                            onSave={(result) => {
                                if (result.value !== '') {
                                    setUser({...user, Surname: result.value })
                                }
                                else {
                                    showWarning({title: 'Surname is required', message: 'Surname cannot be blank', closeTimeout:5})
                                }
                            }}
                        />  
                    </Col>
                </Row>
                <Row className='top-buffer'>
                    <Col xs={4}>
                        <Label>Email</Label>
                    </Col>
                    <Col xs={8}>
                    <EditText
                            inline
                            placeholder="Email"
                            defaultValue={user?.Email ?? ''}
                            onSave={(result) => {
                                if (result.value) {
                                    // validate email
                                    let regex = new RegExp('/^w+[+.w-]*@([w-]+.)*w+[w-]*.([a-z]{2,4}|d+)$/i')
                                    if (regex.test(result.value)) {
                                        setUser({...user, Email: result.value })
                                    }
                                    else {
                                        showWarning({title: 'Email not valid', message: 'Valid email address required', closeTimeout:5})
                                    }

                                }
                                else {
                                    showWarning({title: 'Email is required', message: 'Surename cannot be blank', closeTimeout:5})
                                }
                            }}
                        /> 
                    </Col>

                </Row>
                <Row className='top-buffer'>
                    <Col xs={4}>
                        <Label>New Password</Label>
                    </Col>
                    <Col xs={8}>
                    <EditText
                            inline
                            placeholder="Type new password to reset"
                            defaultValue={''}
                            onSave={(result) => {
                                if (result.value) {
                                    setUser({...user, Password: result.value})
                                }
                            }}   
                        /> 
                    </Col>
                </Row>                
                </Form>
            }
            footer={
                <>
                <Button variant="secondary"
                    onClick={()=> {props.handleHide()}}>
                    Cancel
                </Button>
                <Button variant="primary"
                    onClick={() => {
                        props.handleSave(user);
                    }}>
                    Save
                </Button>
            </>
            }
        />
        )


}