import { API_ENDPOINTS, API_URL, AUTH_API_URL, USER_DETAILS } from "../Globals";
import { IPrefs, IUserDetails } from "../models/IPreferences";
import { get,  HttpResponse, post } from '../services/FetchWrapper';

export interface ICredentials {
    username: string;
    password: string;
    useladder?: boolean;
}
export interface IToken {
    token_type: string | null;
    access_token: string | null;
    expires_in: number | null;
    refresh_token: string | null;
}

export interface IUser {
    token: IToken | null;
    userDetails?: IUserDetails;
}

const store = localStorage; // or sessionStorage

async function loginUser(credentials: ICredentials): Promise<HttpResponse<IToken>> {
    console.log(`LoginUser`)
    let url = `${AUTH_API_URL}/token?grant_type=password&username=${encodeURIComponent(credentials.username)}&password=${encodeURIComponent(credentials.password)}&useladder=${credentials.useladder}`
    return post<IToken>(url,'')
    }
const Auth = {
     signIn(credentials: ICredentials): Promise<HttpResponse<IToken>> {
        return loginUser(credentials);
    },
    async signOut(): Promise<IToken | null> {
        console.log(`signout`)
        localStorage.removeItem(USER_DETAILS);
        return null
    },
    async getUserDetails(tk: IToken | undefined): Promise<HttpResponse<IUserDetails | undefined>> {
        let url = `${AUTH_API_URL}/GetUserDetails`;
        return get(url, tk);
    },
    async saveUser(user:IUser) {
       
        // Write user details to local storage & prefs back to database...
        store.setItem(USER_DETAILS, JSON.stringify(user));
        // also post prefs back to db
        if (user?.userDetails?.userPrefs) {
            let prefs:IPrefs = user.userDetails?.userPrefs
            post<IPrefs>(`${API_URL}/${API_ENDPOINTS.SAVEPREFS}`,prefs,user?.token)
            }   
    }
}
export default Auth