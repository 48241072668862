import * as React from "react";
import { useEffect, useState, createContext,ReactNode, useCallback } from 'react';
import { dbFlight,  dbLogFile, FlightAction, FlightActionType, fromdbFlight, IFlight, makedbFlight, useFlightReducer } from "../models/Flight";


import { DateTime } from "luxon";
import { useCompetitions } from "./CompetitionsContext";
import { useAuth } from "../Auth/AuthProvider";
import { get, retrieveItems, post, getItem } from "../services/FetchWrapper";
import { API_ENDPOINTS, API_URL, REFRESH_INTERVAL } from "../Globals";
import { useErrorHandler } from "react-error-boundary";
// eslint-disable-next-line
import { Log, LogError } from "../services/Logging";
import { useCompetitors } from "./CompetitorsContext";

import _ from 'lodash';
import useInterval from "../components/Utility/UseInterval";

const ENDPOINT = API_ENDPOINTS.FLIGHTS;

export interface FlightsContextData {
    flights: IFlight[];
    dbFlights: dbFlight[];
    refreshDbFlights: (flts:IFlight[])=> void;
    flightsDispatch: (action: FlightAction) => void;
    findFlight: (competitorid: string | number | undefined, flightdate: string | undefined) => IFlight | undefined;
    dayFlights: (flightdate: string| undefined, compid: number | undefined) => IFlight[] ;
    compDates: (compid: number) => string[];
    compFlights: ( compid: number | undefined) => IFlight[] ;
    compFlightsByDate: (compid:number) => dbFlight[][];
    compFlightsForDate: (compid:number, date:string) => dbFlight[];
    getLogFile: (flightid:number) => Promise<dbLogFile | undefined>;
    saveLogFile: (logfile:dbLogFile) => void;
    loading: boolean;
    saving:boolean;
}


const FlightsContext = createContext<FlightsContextData>(
    {   
        flights: [], 
        dbFlights: [], 
        refreshDbFlights: ()=> undefined,
        flightsDispatch: () => undefined, 
        findFlight: () => undefined, 
        dayFlights: ()=> [],
        compDates: ()=>[],
        compFlights: ()=> [],
        compFlightsByDate: ()=>[],
        compFlightsForDate: ()=>[],
        getLogFile: async ()=>undefined,
        saveLogFile: async ()=>undefined,
        loading:false,
        saving:false,
     });

export const FlightsProvider = ({ children }: { children: ReactNode }) => {
    const auth = useAuth();
    const handleError = useErrorHandler();
    
    const [flights, flightsDispatch] = useFlightReducer([])
    const [dbflights, setDbflights] = useState<dbFlight[]>([]);

    const {selectedCompetition} = useCompetitions();
    const {competitors} = useCompetitors();
    
    const [loading, setLoading] = useState(false);
    const [saving, setSaving] = useState(false);
    const [refreshcount, setRefreshcount] = useState<number|undefined>();

       
    const findFlight = useCallback((competitorid: string | number | undefined, flightdate: string | undefined) => {
        //Log(`findFlight: Competitor ${competitorid} on ${flightdate} in `, flights)
        let flt = flights.find(flight => { return flight.CompetitorID === competitorid && (flightdate ? flight.FlightDate === flightdate : true) });
        return flt;
    },[flights])

    const dayFlights = useCallback((flightdate: string | undefined, compid: number | undefined) => {
        //flightdate is an ISO date
        let datefilter = flightdate ? DateTime.fromISO(flightdate).toISODate() : undefined;
        let dayflts = datefilter ? flights.filter(flt => { return flt.FlightDate=== datefilter && flt.CompetitionID===compid }) : []
        return dayflts;
    },[flights])

    const compFlights = useCallback(( compid: number | undefined) => {
        let compflts = flights.filter(flt => { return  flt.CompetitionID===compid })
        compflts.sort((a, b)=>{            
            return (a.FlightDate ?? '').localeCompare((b.FlightDate ?? ''))
        })
        return compflts
    },[flights])

    const compDates = useCallback((compid:number)=> {
        let flts = flights.filter(f=>f.CompetitionID===compid);
        let dates = _.uniq(_.map(flts,'FlightDate'));
        // sort the dates into descending order - they are ISO date strings so a simple string comparison works
        dates.sort((a,b)=>{ return b.localeCompare(a)});  // we want the most recent days first
        return dates;
    },[flights])

    const compFlightsForDate = useCallback((compid:number, date:string) => {
        let dayflts =  dbflights.filter(flt=>flt.FlightDate===date && flt.CompetitionID===compid);
        // add any competitors for whom we don't have a flight so they show up as DNF
        let lastid = Math.max(...dayflts.map((flt=>flt.ID))) 
        const missing = competitors.filter(c=>dayflts.map(flt=>flt.CompetitorID).indexOf(c.ID ?? 0)===-1);
        missing.forEach(competitor=>{            
            dayflts.push({
                ID: ++lastid,
                CompetitionID:selectedCompetition?.ID ?? 0,
                CompetitorID: competitor.ID ?? 0,
                FlightDate: date,
                TaskID:0,
            })
        });
        return dayflts;
    },[dbflights,competitors, selectedCompetition])

    const compFlightsByDate = useCallback((compid:number)=> {

        let dates = compDates(compid);
        // create a separate array of flights for each date
        let fltsbydate = dates.map(fltdate=> {
            return compFlightsForDate(compid, fltdate)
        });
        // now sort each array in descending order of totalpoints
        fltsbydate.forEach((flts, index)=>{
            flts.sort((a,b)=>{return (b.TotalPoints ?? 0) - (a.TotalPoints ?? 0)})

            });

        return fltsbydate;
    },[compDates, compFlightsForDate])
    
    const getLogFile = async (flightid: number):Promise<dbLogFile | undefined > => {
        setLoading(true);
        return get<dbLogFile>(`${API_URL}/LogFile/${flightid}`,auth?.user?.token)
            .then(resp=> {
            return resp.parsedBody
        })
        // .catch(e=> {

        //     handleError( new Error (`getLogFile error: ${(e as Error).message}`));
        //     return undefined;
        // })
        .finally(()=>{ setLoading(false)})
    }

    const saveLogFile = async(logfile: dbLogFile) =>{
        setSaving(true);
        await post<dbLogFile>(`${API_URL}/SaveLogFile/`,logfile,auth?.user?.token)
        .catch(e=> {
            LogError(`saveLogFile: ${(e as Error).message}`)
            handleError( new Error (`saveLogFile error: ${(e as Error).message}`))
         })
        .finally(()=>{ setSaving(false)})
    }

    const refreshDBFlights = (flts:IFlight[]) => {
        //update the dbFlights from the main Flights array...
        setDbflights(flts.map(flt=>{
            return makedbFlight(flt)
        }))
    }

    // Set up an interval timer to check whether we need to update from the database
    useInterval(async ()=>{
        async function getcount(compid:number) {      
            return await getItem<number>(`${API_URL}/${API_ENDPOINTS.GETRESULTSCOUNT}/${compid }`)
        }
        if (selectedCompetition)  {
            let count =  await getcount(selectedCompetition.ID ?? 0);
            setRefreshcount(val=> {return (count ?? 0)});
        }
    }, REFRESH_INTERVAL);

    useEffect(() => {
        const getFlights = async () => {
            let compid = selectedCompetition?.ID ?? '';
            //let userid = auth?.user?.userDetails?.userid ?? '';
            let token = auth?.user?.token ?? null;
            if (compid !== '') {
                Log(`getFlights for comp ${compid}`)
                setLoading(true);
                retrieveItems<dbFlight>(`${API_URL}/${ENDPOINT}/${compid}`, '', true,token)
                .then( async items =>  {
                    setLoading(false);
                    if (items) {                        
                        // save in dbFlights 
                        setDbflights(items);
                        // convert from dbFlights & set flights
                        let flts:IFlight[] = [];
                        items.forEach(flt=> {
                            flts.push(fromdbFlight(flt));
                        })
                        await flightsDispatch({
                            type: FlightActionType.LOAD,
                            payload: { value: flts }
                        });
                    } 
                })
                .catch(e=>{
                    LogError(`getFlights: ${(e as Error).message}`)
                    handleError( new Error(`getFlights error ${(e as Error).message}`))
                })
                .finally(()=>{
                    setLoading(false)
                });
            }
            else {
                // not logged in...
                flightsDispatch({
                    type: FlightActionType.CLEAR,
                    payload: { }
                });
            }
        }      
        getFlights()
            .catch(e=>{
                LogError(`getFlights: ${(e as Error).message}`)
                handleError(new Error(`getFlights error ${(e as Error).message}`))})
        
    }, 
// supress warning that dispatch function should be in dependencies
// eslint-disable-next-line  
    [selectedCompetition, auth?.user, refreshcount]);
   


    return (
        <FlightsContext.Provider value={
            { 
            flights: flights, 
            dbFlights: dbflights,
            refreshDbFlights: refreshDBFlights,
            flightsDispatch: flightsDispatch,
            findFlight: findFlight, 
            dayFlights: dayFlights,
            compFlights: compFlights,
            compDates: compDates,
            compFlightsByDate: compFlightsByDate,
            compFlightsForDate: compFlightsForDate,
            getLogFile: getLogFile,
            saveLogFile: saveLogFile,
            loading: loading,
            saving: saving,

            }}>
            {children}
        </FlightsContext.Provider>
    )
}


export function useFlightsContext() {
    const context = React.useContext(FlightsContext);
    if (context === undefined) {
        throw new Error("useFlightsContext must be used within a FlightsProvider");
    }
    return context;
}

export const useFlights = (): FlightsContextData=> {
    const context = useFlightsContext();
    return context;
}
export const FlightsConsumer = FlightsContext.Consumer;

export default FlightsContext;