import * as React from "react";
import { useState, useEffect, createContext,  ReactNode } from 'react';

import { LASTDATEKEY } from "../Globals";

import { useErrorHandler } from 'react-error-boundary';

import { Log } from "../services/Logging";
import { DateTime } from "luxon";

export interface DateContextData {
    selectedDate: string;       //ISO Date
    setDate: (dt:string)=>void;
    }

const DateContext = createContext<DateContextData>(
    { 
        selectedDate: '',
        setDate: () => undefined,
    });

export const DateProvider = ({ children }: { children: ReactNode }) => {
    const [selectedDate, setSelectedDate] = useState('')
    // eslint-disable-next-line
    const handleError = useErrorHandler()
    useEffect(() => {
         function getLastDate() {
            let sd = localStorage.getItem(LASTDATEKEY);
                // dt is an ISO date, we want just the date bit
            let dt = DateTime.fromJSDate(new Date()).toISODate();
            if (sd) {
                try{
                    setSelectedDate(DateTime.fromISO(sd).toISODate()!);
                }
                catch {}
            }
            return dt;
        }
        getLastDate()      
    },        
    []);


const setDate = (dt:string) => {
    //Log(`DateContext: setDate with ${dt}`)
    let date = '';
    try {
        date = DateTime.fromISO(dt).toISODate()!;

    }
    catch {
        Log (`setDate received invalid date: ${dt}`)
    }
    finally {
        //Log(`DateContext: set date to ${date}`)
        setSelectedDate(date);
        // save to storage
        localStorage.setItem(LASTDATEKEY, date)
    }

}
    
    return (
        <DateContext.Provider value={
            {
                selectedDate: selectedDate,
                setDate: setDate
            }}>
            {children}
        </DateContext.Provider>
    )
}

export function useDateContext() {
    const context = React.useContext(DateContext);
    if (context === undefined) {
        throw new Error("useDateContext must be used within a DateProvider");
    }
    return context;
}

export const useDate = (): DateContextData=> {
    const context = useDateContext();
    return context;
}
export const DateConsumer = DateContext.Consumer;

export default DateContext;

