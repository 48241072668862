import * as React from "react";
import { useState, useEffect, createContext,  ReactNode } from 'react';

import IGlider from '../models/IGlider';
import { retrieveItems } from '../services/FetchWrapper';
import { useErrorHandler } from "react-error-boundary";
import {   LogError } from "../services/Logging";

const ENDPOINT = 'https://api.bgaladder.net/api/gliders';
export interface GliderContextData {
    gliders: IGlider[];
    findGlider: (id: string | number | undefined) => IGlider | undefined;
    loading:boolean;
}

const GlidersContext = createContext<GliderContextData>({ gliders:  [], findGlider : () => undefined, loading:false  });

export const GlidersProvider = ({ children }: { children: ReactNode }) => {
    const [gliders, setGliders] = useState<IGlider[]> ([])
    // eslint-disable-next-line
    const [loading, setLoading] = useState(true);
    const handleError = useErrorHandler()
   
    const findGlider = (id: number | string | undefined):IGlider | undefined => {
        let numid = typeof id=== 'string' ? parseInt(id) : id;
        let gl = numid ? gliders.find(gl => { return gl.GliderID === numid }): undefined;
        return gl;
    }

    useEffect(() => {
        const getGliders = async () => {
            //Log(`getGliders`)
            setLoading(true);
            retrieveItems<IGlider>(ENDPOINT)
            .then( items =>  {
                setGliders(items ?? []);
            })
            .catch(e=>{
                LogError(`getGliders: ${(e as Error).message}`)
                handleError(new Error(`getGliders error ${(e as Error).message}`))
            })
            .finally(()=>{
                setLoading(false)
            });
         }
        getGliders();
    }, 
    // eslint-disable-next-line
    [])

    return (
        <GlidersContext.Provider value={{ gliders: gliders, findGlider: findGlider, loading:loading }}>
            {children}
        </GlidersContext.Provider>
        )
}

export function useGliderContext() {
    const context = React.useContext(GlidersContext);
    if (context === undefined) {
        throw new Error("useGliderContext must be used within a GliderProvider");
    }
    return context;
}

export const useGliders = (): GliderContextData => {
    const context = useGliderContext();
    return context;
}
export const GlidersConsumer = GlidersContext.Consumer;
export default GlidersContext;