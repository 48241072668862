import * as React from 'react';
import { Container } from 'reactstrap';
import NavMenu  from './NavMenu';

export const Layout: React.FC<React.PropsWithChildren<unknown>> = (props) => {
  return (
    <div>
    <NavMenu />
    <Container>
      {props.children}
    </Container>
  </div>
  )
}

